import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import map from '../../assets/Map.png';
import time from '../../assets/Time4.png';

const Home = () => {
  const descriptionRef1 = useRef(null);
  const imageRef1 = useRef(null);
  const descriptionRef2 = useRef(null);
  const imageRef2 = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null, // viewport
      rootMargin: '0px', // no margin
      threshold: 0.9 // 50% of the element visible
    };

    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          descriptionRef1.current.classList.add('slide-up');
          imageRef1.current.classList.add('slide-left');
        } else {
          descriptionRef1.current.classList.remove('slide-up');
          imageRef1.current.classList.remove('slide-left');
        }
      });
    }, observerOptions);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          descriptionRef2.current.classList.add('slide-up');
          imageRef2.current.classList.add('slide-right');
        } else {
          descriptionRef2.current.classList.remove('slide-up');
          imageRef2.current.classList.remove('slide-right');
        }
      });
    }, observerOptions);

    observer1.observe(descriptionRef1.current);
    observer1.observe(imageRef1.current);
    observer2.observe(descriptionRef2.current);
    observer2.observe(imageRef2.current);

    // Cleanup
    return () => {
      observer1.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <div className="home__container">
      <div className="home__content">
        <div className="home__description" ref={descriptionRef1}>
          <h2>Timeline</h2>
          <p>Explore the evolution of support for Trump and Biden according to social polls and traditional polls.</p>
          <div className="home__buttons">
            <Link to="/timechart">
              <button>View Timeline →</button>
            </Link>
          </div>
        </div>
        <div className="home__image2" ref={imageRef1}>
          <Link to="/timechart">
            <img src={time} alt="Description 2" />
          </Link>
        </div>
      </div>
      <div className="home__content">
        <div className="home__image1" ref={imageRef2}>
          <img src={map} alt="Description 1" />
        </div>
        <div className="home__description" ref={descriptionRef2}>
          <h2>Map</h2>
          <p>View an interactive map of how states are projected to vote based on polls published on X.</p>
          <div className="home__buttons">
            <button disabled>Coming soon</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
