import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Navbar, Header } from './components';
import { Map, Media, Methodology, People, VoteChart, Home } from './containers';
import './App.css';
import ReactGA from 'react-ga';

const App = () => {
  return (
    <Router>
      <div className="App">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <MainContent />
      </div>
    </Router>
  );
}

const MainContent = () => {
  const location = useLocation();

  console.log(location.pathname);
  console.log(location.search);
  useEffect(() => {
    ReactGA.initialize('G-4XT31WZKKJ'); 
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div>
      <Routes>
        <Route 
          path="/" 
          element={
            <>
              <Header /> 
              <Home /> 
            </>
          } 
        />
        <Route 
          path="/home" 
          element={
            <>
              <Header /> 
              <Home /> 
            </>
          } 
        />
        <Route path="/timechart" element={<VoteChart />} />
        <Route path="/methodology" element={<Methodology />} />
        <Route path="/media" element={<Media/>} />
        <Route path="/people" element={<People />} />
        <Route path="/map" element={<Map />} />
      </Routes>
    </div>
  );
}

export default App;
