import React, { useState, useEffect, useRef } from 'react';
import USAMap from 'react-usa-map';
import biasedData from '../../jsons/biasedData.json';
import correctedData from '../../jsons/correctedData.json';
import stateConversion from '../../jsons/stateAbbreviations.json';
import './Map.css';
import stateNames from '../../jsons/stateNames.json';

const Map = () => {
  const [data, setData] = useState(biasedData);
  const [stateAbbreviations, setStateAbbreviations] = useState({});
  const [selectedOption, setSelectedOption] = useState('biased'); // Changed from activeButton
  const [hoveredState, setHoveredState] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const mapRef = useRef(null);
  const tooltipRef = useRef(null);

  console.log(showTooltip);

  useEffect(() => {
    const abbreviations = {};
    for (const state in stateConversion) {
      abbreviations[state] = stateConversion[state];
    }
    setStateAbbreviations(abbreviations);
  }, []);

  useEffect(() => {
    const states = mapRef.current?.querySelectorAll("[data-name]");
    states?.forEach((state) => {
      state.addEventListener("mouseenter", handleMouseEnter);
      state.addEventListener("mouseleave", handleMouseLeave);
      state.addEventListener("mousemove", handleMouseMove);
    });
    return () => {
      states?.forEach((state) => {
        state.removeEventListener("mouseenter", handleMouseEnter);
        state.removeEventListener("mouseleave", handleMouseLeave);
        state.removeEventListener("mousemove", handleMouseMove);
      });
    };
  }, [mapRef, data]);

  const handleMouseEnter = (event) => {
    const stateName = event.target.getAttribute("data-name");
    if (stateName) {
      setHoveredState({ name: stateName });
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setHoveredState(null);
    setShowTooltip(false);
  };

  const handleMouseMove = (event) => {
    setTooltipPosition({ x: event.clientX + 30, y: event.clientY - 30 });
  };

  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    setSelectedOption(selected);
    if (selected === 'biased') {
      setData(biasedData);
    } else if (selected === 'corrected') {
      setData(correctedData);
    }
  };

  const calculateTotalPercentages = () => {
    let totalDemocratic = 0;
    let totalRepublican = 0;
    let maxDemocratic = 0;
    let maxRepublic = 0;
    Object.values(data).forEach((data) => {
      if (data.fill === "blue") {
        totalRepublican += data.percentage;
        maxRepublic = Math.max(maxRepublic, data.percentage);
      } else {
        totalDemocratic += data.percentage;
        maxDemocratic = Math.max(maxDemocratic, data.percentage);
      }
    });
    return { totalDemocratic, totalRepublican, maxDemocratic, maxRepublic };
  };

  const {
    totalDemocratic,
    totalRepublican,
    maxDemocratic,
    maxRepublic,
  } = calculateTotalPercentages();

  const totalVotes = totalDemocratic + totalRepublican;

  const democraticPercentage = (totalDemocratic / totalVotes) * 100;
  const republicanPercentage = (totalRepublican / totalVotes) * 100;
  const winner = democraticPercentage > republicanPercentage ? "Democratic" : "Republican";

  const finalBlue = Math.round(255 * (maxRepublic / 100));
  const finalRed = Math.round(255 * (maxDemocratic / 100));
  const blueColorDark = `rgb(0, 0, ${finalBlue})`;
  const blueColorLight = `rgb(0, 0, ${finalBlue + 100})`;
  const redColorDark = `rgb(${finalRed}, 0, 0)`;
  const redColorLight = `rgb(${finalRed + 100}, 0, 0)`;

  const calculateFillColor = (percentage, state) => {
    const intensity = Math.round(255 * ((100 - percentage) / 100));
    const color = data[state].fill === "red" ? `rgb(${intensity}, 0, 0)` : `rgb(0, 0, ${intensity})`;
    return color;
  };

  const statesCustomConfig = () => {
    const customConfig = {};
    for (let state in data) {
      const stateAbbreviation = stateAbbreviations[state];
      const percentage = data[state].percentage;
      const fillColor = calculateFillColor(percentage, state);
      customConfig[stateAbbreviation] = {
        fill: fillColor,
        "data-name": state,
      };
    }
    return customConfig;
  };

  return (
    <div className='container'>
      <div className='map-container'>
        <select
          className='toggle-dropdown'
          value={selectedOption}
          onChange={handleDropdownChange}
        >
          <option value='biased'>Show Original Data</option>
          <option value='corrected'>Show Corrected Data</option>
        </select>
        <div ref={mapRef}>
          <USAMap customize={statesCustomConfig()} />
        </div>
      </div>
      {hoveredState && data[stateNames[hoveredState.name]] && (
        <div
          className="tooltip"
          ref={tooltipRef}
          style={{
            top: `${tooltipPosition.y}px`,
            left: `${tooltipPosition.x}px`,
          }}
        >
          <div className="tooltip-header">
            <p><strong>State: </strong>{stateNames[hoveredState.name]}</p>
          </div>
          <div className="tooltip-body">
            <p><strong>Winner:</strong> {data[stateNames[hoveredState.name]].winner}</p>
            <p><strong>Percentage:</strong> {data[stateNames[hoveredState.name]].percentage}%</p>
            <p><strong>Color:</strong> {data[stateNames[hoveredState.name]].fill}</p>
          </div>
        </div>
      )}
      
      <div className='info-container'>
        <div className='info-part'>
          <h2>
            {selectedOption === "biased"
              ? "Original Map:"
              : "Corrected Map:"}
          </h2>
          <p>
            {selectedOption === "biased"
              ? "This map illustrates election results from social media polls ( X ), reflecting public opinion online, acknowledging biases inherent in such surveys."
              : "This map improves upon previous data by using regression and postratification methods, incorporating demographic variables like gender and religion."}
          </p>
        </div>
        <div className='horizontal-line'></div>
        <div className='info-part'>
          <h2>
            Results:
          </h2>
          <p>
            <strong>Total Votes:</strong> {totalVotes}
          </p>
          <p>
            <strong>Democratic Votes:</strong> {totalDemocratic} ({democraticPercentage.toFixed(2)}%)
          </p>
          <p>
            <strong>Republican Votes:</strong> {totalRepublican} ({republicanPercentage.toFixed(2)}%)
          </p>
          <p>
            {winner === "Democratic"
              ? <p><strong>Winner : </strong><span className='color-box' style={{backgroundColor: `blue`}}></span> Democratic</p>
              : <p><strong>Winner : </strong><span className='color-box' style={{backgroundColor: `red`}}></span> Republican</p>
            }
          </p> 
        </div>
        <div className='horizontal-line'></div>
        <div className='info-part'>
          <h2>
            Gradient Summary:
          </h2>
          <p>
            <span className='color-box' style={{backgroundColor: `${redColorDark}`}}></span> Maximum Votes won by Republican
          </p>
          <p>
            <span className='color-box' style={{backgroundColor: `${redColorLight}`}}></span> Minimum Votes won by Republican
          </p>
          <p>
            <span className='color-box' style={{backgroundColor: `${blueColorDark}`}}></span> Maximum Votes won by Democratic
          </p>
          <p>
            <span className='color-box' style={{backgroundColor: `${blueColorLight}`}}></span> Minimum Votes won by Democratic
          </p>
        </div>
      </div>
    </div>
  );
};

export default Map;
